.main {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .main_container {
        margin: 80px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .left_side_menu {
            display: flex;
            flex-direction: column;
            .menu_list {
                position: sticky;
                top: 100px;
                font-size: 15px;
                display: flex;
                flex-direction: column;
                gap: 7px;
                .menu_item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 7px 10px;

                    border-radius: 5px;
                    transition: background-color 0.2s;
                    cursor: pointer;
                    &:hover {
                        background: rgba(0, 0, 0, 0.1);
                    }
                    .menu_item_icon {
                        height: 25px;
                        display: flex;
                        align-items: center;
                        svg {
                            width: 25px;
                            height: auto;
                            aspect-ratio: 1/1;
                        }
                    }
                    .menu_item_text {
                        margin-left: 10px;
                    }
                }
                .active_menu_item {
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                }
            }
        }
        .active_block {
            width: 700px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .settings {
            width: 400px;
            gap: 12px;
            display: flex;
            flex-direction: column;
            .settings_item {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;
            }
            .submit {
                width: 100%;
            }
        }
        .extension_settings {
            width: 100%;
            gap: 12px;
            display: flex;
            flex-direction: column;
            .download_extension_row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .extension_description {
                    width: 500px;
                }
                .download_extension_button {
                    padding: 10px 15px;
                    font-size: 15px;
                    border-radius: 7px;
                    border: none;
                    background-color: rgba(224, 224, 224, 0.68);
                    color: #000000;
                    font-weight: 400;
                    transition: .3s;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(224, 224, 224, 1);
                        cursor: pointer;
                    }
                }
            }
            .tokens_editor {
                width: 100%;
                .extension_header {
                    margin-top: 5px;
                    font-size: 15px;
                    font-weight: 600;
                }
                .tokens_popup_add_row {
                    margin-top: 10px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    .tokens_popup_add_row_input {
                        width: 50%;
                    }
                    .tokens_add_responsible_selector {
                        width: 50%;
                    }
                    button {
                        aspect-ratio: 1 / 1;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
                .tokens_list {
                    font-size: 15px;
                    margin-top: 15px;;
                    width: 100%;
                    border: none;
                    margin-bottom: 20px;
                    border-spacing:0;
                    border-radius: 7px;
                    overflow: hidden;
                    font-weight: bolder;

                    thead th {
                        font-weight: bold;
                        text-align: left;
                        border: none;
                        padding: 10px 15px;
                        background: #d8d8d8;
                        font-size: 13px;
                    }
                    tbody td {
                        text-align: left;
                        padding: 10px 15px;
                        font-size: 13px;
                        vertical-align: top;
                    }
                    thead tr th:first-child, .table tbody tr td:first-child {
                        border-left: none;
                    }
                    thead tr th:last-child, .table tbody tr td:last-child {
                        border-right: none;
                        width: 40px;
                    }
                    .token_delete {
                        cursor: pointer;
                        color: red;
                        &:hover {
                            color: rgba(255, 0, 0, 0.49);
                        }
                    }
                    .token_copy {
                        width: 30px;
                        cursor: pointer;
                        color: #1976d2;
                        text-align: center;
                        vertical-align: middle;

                        &:hover {
                            opacity: 50%;
                        }
                        &:active {
                            opacity: 100% !important;
                        }
                        svg {
                            margin-top: 2px;
                            height: 15px;
                            width: auto;
                            stroke-width: 2.5 !important;
                        }
                    }
                    tbody tr:nth-child(even){
                        background: #f3f3f3;
                    }
                }
            }

            .ext_install_guide_link {
                color: #007eff;
                font-size: 15px;
                font-weight: 400;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .hh_automatization {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 400px;
            text-align: left;
            .hh_authorize_button {
                font-size: 14px;
                border-radius: 7px;
                border: none;
                background-color: #e0beff;
                background-image: linear-gradient(to right, #2FC4F4, #481173);
                height: 40px;
                font-weight: 400;
                transition: .3s;
                cursor: pointer;
                color: white;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                &:hover {
                    opacity: .7;
                    cursor: pointer;
                }
            }

            .save_hh_settings {
            }

            .deauth_link {
                text-align: right;
            }

            .lesson {
                width: 500px;
                height: 300px;
                border-radius: 7px;
                overflow: hidden;

                iframe {
                    width: 100%;
                    height: 100%;
                }
            }

            .all_lessons_button {
                font-size: 15px;
                border-radius: 7px;
                border: none;
                background-color: #e0beff;
                color: #000000;
                height: 50px;
                font-weight: 400;
                transition: .3s;
                cursor: pointer;

                &:hover {
                    background-color: #c293f5;
                    cursor: pointer;
                }

            }
        }
    }
}